.Widget {
  background-color: #eee;
  padding: 1rem;
}

/* autoprefixer: off */
@supports (display: grid) {
  .Widget {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "header"
      "content";
  }

  .App > * + * {
    margin-top: 0;
  }
}

.Widget .header {
  grid-area: header;
  line-height: 2;
  display: flex;
  justify-content: space-between;
}

.Widget h2 {
  font-size: 1.5rem;
  margin: 0;
  line-height: 1;
  text-overflow: ellipsis;
}

.Widget .Loading {
  margin-left: 0.5rem;
}

.Widget .content {
  grid-area: content;
  padding-top: 1rem;
}

@media (max-width: 600px) {
  .Widget .content {
    padding-top: 0.25rem;
  }
}
